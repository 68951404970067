import React from 'react';
import { Helmet } from 'react-helmet';

const PageMeta = ({ title }) => {
  return (
    <Helmet>
      <title>Paltzcon - Web & Mobile Application Development</title>
      <meta name="description" content="We specialize in React Native, serverless architecture, microservices, and more to create custom solutions for your business needs." />
    </Helmet>
  );
};

export default PageMeta;
